<template>
  <div>
    <div class="page inner-container about">
      <div class="about--body">
        <component
          v-for="(moduleSingle, i) in aboutModule"
          :key="i"
          :is="moduleSingle.type"
          :data="moduleSingle.content"
          :slug="moduleSingle.type"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import about from "@/views/About/parts/aboutUs";
import past_event from "@/views/About/parts/pastEvent";
import acknowledgment from "@/views/About/parts/ack";
import team from "@/views/About/parts/team";

export default {
  name: "AboutIndex",
  components: { about, past_event, acknowledgment, team },
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE"),
      aboutModule: {}
    };
  },

  beforeMount() {
    this.apiAbout();
  },

  mounted() {},

  methods: {
  

    scrollToSection(el) {
      let navH = document.querySelector(".about--nav--wrap").offsetHeight;
      let targetEl = document.querySelector(`#${el}`).getBoundingClientRect();
      let targetElPosTop = targetEl.top + window.scrollY - navH;
      window.scrollTo({
        top: targetElPosTop,
        behavior: "smooth"
      });
    },

    async apiAbout() {
      try {
        const response = await fetch(
          // `http://uat.graphicarchive.hk/api/${this.lang}/about/`,
          `${this.WP_API_URL}${this.lang}/about/`,
          {
            method: "GET",
            mode: "cors",
            cache: "default"
          }
        );
        if (!response.ok) {
          throw new Error("API Error");
        }

        const data = await response.json();

        let pastEvents = data.module.filter(module => module.type == 'past_event');

        this.aboutModule = pastEvents;

      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page.about {
  padding-top: 40px;
  @include respond-to(screen-md) {
    padding-top: 50px;
  }
}

.about {
  &--nav {
    background-color: #e6e6e6;
    // margin-bottom: 50px;
    position: relative;
    height: 100%;
    // padding: 40px 0;w
    width: 100%;
    padding: 0 20px;
    @include respond-to(screen-sm) {
      padding: 0 40px;
    }
    &--wrap {
      pointer-events: all;
      position: absolute;
      z-index: 99;
      top: 0;
      height: 60px;
      background-color: #e6e6e6;
      // padding: 40px 0;
      width: 100%;
      @include respond-to(screen-sm) {
        height: 80px;
      }
    }
    .pd {
      display: flex;
      align-content: center;
      position: relative;
      height: 100%;
      // padding: 30px 0;
    }
    &--item {
      // margin-right: 40px;
      margin: auto 40px auto 0;
      a {
        @include transition(all 300ms ease-in-out);
        display: block;
        font-size: 16px;
        @include respond-to(screen-sm) {
          font-size: 20px;
        }
      }
    }
    .pin-spacer {
      pointer-events: none;
    }
  }
}
.inner-wrap {
  position: relative;
  overflow: hidden;
  height: 60px;
  @include respond-to(screen-sm) {
    height: 80px;
  }
}
</style>
